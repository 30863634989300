<template>
    <div class="riskBox">
        <div class="tabs">
            <div class="menuTabs">
                <div :class="['tabItem', tabPosition === item.value ? 'active-tab' : '']" v-for="item in tabsList" :key="item.value" @click="handleClick(item.value)">
                    {{ item.name }}
                </div>
            </div>
        </div>
        <div class="tabs_right">
            <el-select v-model="resonId" class="mr20" placeholder="请选择预警原因" clearable v-if="tabPosition !== '全部'">
                <el-option v-for="item in resonList" :key="item.eventReasonId" :label="item.eventReason" :value="item.eventReasonId"></el-option>
            </el-select>
            <!--			<el-select v-model="orgId" placeholder="请选择所属组织" filterable clearable>-->
            <!--				<el-option v-for="item in streetList" :label="item.streetName" :value="item.streetId" :key="item.streetId"></el-option>-->
            <!--			</el-select>-->
            <div class="lable-box">
                <span class="labelFont">所属组织</span>
                <el-cascader
                    clearable
                    placeholder="请选择所属组织"
                    v-model="streetArr"
                    :props="defaultProps"
                    style="margin-right: 20px"
                    ref="supervisedUnitCode"
                    :show-all-levels="false"
                    filterable
                    :options="streetList"
                ></el-cascader>
            </div>
            <div class="lable-box">
                <span class="labelFont">服务机构</span>
                <el-select v-model="sysId" placeholder="请选择服务机构" filterable clearable>
                    <el-option v-for="item in organList" :label="item.serviceName" :value="item.id" :key="item.id"></el-option>
                </el-select>
            </div>
            <div class="lable-box">
                <span class="labelFont">搜索内容</span>
                <el-input v-model="keyWord" clearable placeholder="请输入内容" style="width: 200px" />
            </div>
            <el-button type="primary" class="search" @click="selectRiskEventList">搜索</el-button>
            <el-button type="primary" plain style="float: right; margin-right: 20px" @click="downLoad">导出</el-button>
        </div>
        <div class="content">
            <el-table
                ref="table"
                v-loading="loading"
                :data="tableData"
                :row-style="{ height: '55px' }"
                style="width: 100%"
                border
                :header-cell-style="headerCellStyle"
                @row-click="toogleExpand"
                @sort-change="sortChangeInit"
            >
                <template slot="empty">
                    <IsEmpty />
                </template>
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-form inline class="demo-table-expand">
                            <Map class="map" :longitude="props.row.lon" :latitude="props.row.lat" />
                        </el-form>
                    </template>
                </el-table-column>
                <el-table-column prop="date" label="序号" type="index" width="50" />
                <el-table-column v-if="show_tabs" prop="date" label="事件类型" width="120">
                    <template slot-scope="scope">
                        <div class="date-box">
                            <div class="date-txt">
                                {{ status(scope.row.eventType) }}
                                <span v-if="scope.row.secondaryName">{{ `（${scope.row.secondaryName}）` }}</span>
                            </div>
                            <div class="num-box" v-if="scope.row.eventTimes >= 2">
                                <i>x</i>
                                <span>{{ scope.row.eventTimes }}</span>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="处理状态" width="140">
                    <template slot-scope="scope">
                        <div v-if="scope.row.processStatus === 2" style="color: #999999" class="processStatus">
                            <img class="chu_img" src="../../assets/imgs/finish.png" alt />已处理
                        </div>
                        <div v-else-if="scope.row.processStatus === 0" style="color: #fe0303" class="processStatus">
                            <img class="chu_img" src="../../assets/imgs/weichuli.png" alt />未处理
                        </div>
                        <div v-else-if="scope.row.processStatus === -1" style="color: #fe0303" @click="unhandle(scope.row)" class="processStatus">
                            <img class="chu_img" src="../../assets/imgs/weichuli.png" alt />去处理
                        </div>
                        <div v-else-if="scope.row.processStatus === 1" style="color: #3fcf89" class="processStatus">
                            <img class="chu_img" src="../../assets/imgs/yichuli.png" alt />处理中
                        </div>
                        <div v-else-if="scope.row.processStatus === 3" style="color: #999999" class="processStatus">
                            <el-tooltip class="item" effect="light" content="老人状态安全！" placement="top-end" transition="liner" :enterable="false" :hide-after="1000">
                                <div class="processStatus"><img class="chu_img" src="../../assets/imgs/finish.png" alt />已处理</div>
                            </el-tooltip>
                        </div>
                        <div v-else-if="scope.row.processStatus === -2" style="color: #ffb246" class="processStatus">
                            <img class="chu_img" src="../../assets/imgs/chaoshi.png" alt />超时未处理
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="alertTime" label="预警时间" width="180" sortable="custom" />
                <el-table-column prop="position" label="报警地址" width="180" :show-overflow-tooltip="true" />
                <el-table-column prop="organizationName" label="所属组织" width="120" :show-overflow-tooltip="true" />
                <el-table-column prop="serviceName" label="服务机构" width="120" :show-overflow-tooltip="true" />
                <el-table-column prop="userName" label="姓名" width="100" />
                <el-table-column label="性别" width="50">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sex === 1 ? '男' : '女' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="phone" label="电话" width="120" />
                <el-table-column prop="responderName" label="协助人员" width="100" />
                <el-table-column prop="responderPhone" label="协助人员电话" width="120" />
                <el-table-column prop="eventReason" label="预警原因" width="100" />
                <el-table-column prop="processDetails" label="备注" width="120" />
                <el-table-column prop="responseTime" label="响应时长" width="120" sortable="custom" />
                <el-table-column label="操作" min-width="180" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-popover placement="right" trigger="hover">
                            <detail :id="scope.row.id" :key="scope.row.id" />
                            <el-button slot="reference" type="text">通知进度</el-button>
                        </el-popover>
                        <el-button style="margin-left: 10px" type="text" @click.prevent="seeLog(scope.row)">处理日志</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                style="margin: 20px 0"
                background
                layout="total, sizes, prev, pager, next"
                :total="total"
                :page-size="pageSize"
                :page-sizes="[10, 20, 30, 40]"
                :current-page="pageNum"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </div>
        <div>
            <el-dialog title="紧急情况处理" :visible.sync="dialogFormVisible" :append-to-body="true" width="400px">
                <el-form style="padding: 0 20px">
                    <el-form-item label="是否安全" :label-width="formLabelWidth">
                        <el-switch v-model="isSafe" class="switch" active-color="#13ce66" inactive-color="#ff4949" />
                    </el-form-item>
                    <el-input v-model="textarea" type="textarea" :rows="3" placeholder="处理情况描述" />
                </el-form>
                <div slot="footer" class="dialog-footer" style="text-align: center">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" @click="confirm">确 定</el-button>
                </div>
            </el-dialog>
        </div>
        <!-- 风险日志 -->
        <RiskLog v-if="showLog" :showLog.sync="showLog" :riskId="riskId" />
    </div>
</template>

<script>
import Map from './components/riskComponent/map'
import Detail from './components/riskComponent/detail'
import { selectRiskEventList, updateRiskEventStates, downloadRiskEvent, getRiskEventReason } from '@/api/bedManage.js'
import { streetList, serviceNameList } from '@/api/orderManage'
import { downloadStream } from '@/utils'
import RiskLog from '../assMecManage/components/riskLog.vue'
export default {
    components: {
        Map,
        Detail,
        RiskLog
    },

    data() {
        return {
            defaultProps: {
                value: 'streetId',
                label: 'streetName',
                children: 'streetNameListResultList',
                checkStrictly: true
            },
            streetArr: [],
            total: 0,
            pageNum: 1,
            pageSize: 10,
            tabPosition: '全部',
            tabsList: [
                { name: '全部', value: '全部' },
                { name: '防盗风险', value: '0' },
                { name: '火灾风险', value: '1' },
                { name: '跌倒风险', value: '2' },
                { name: '活跃异常', value: '3' },
                { name: '走丢风险', value: '4' },
                { name: '紧急风险', value: '5' },
                { name: '燃气泄漏', value: '6' },
                { name: '夜间异常', value: '7' },
                { name: '水浸风险', value: '8' },
                { name: '健康风险', value: '9' }
            ],
            show_tabs: true,
            keyWord: '',
            loading: true,
            tableData: [],
            longitude: 116.397428, // 经度
            latitude: 39.90923, // 纬度,
            expandRowKeys: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            dialogFormVisible: false,
            formLabelWidth: '70px',
            isSafe: false,
            safe: 0,
            textarea: '', // 处理情况描述
            riskId: 0,
            orgId: '',
            sysId: '',
            orderBy: '',
            resonId: '',
            resonList: [],
            streetList: [], // 组织列表
            organList: [],
            showLog: false
        }
    },
    created() {
        this.selectRiskEventList()
        this.getOrgan()
        this.getStreetList()
        setInterval(function () {
            // 解决级联选择器不点击圆点选中元素问题
            document.querySelectorAll('.el-cascader-node__label').forEach(el => {
                el.onclick = function () {
                    if (this.previousElementSibling) this.previousElementSibling.click()
                }
            })
        }, 1000)
    },
    methods: {
        // 显示详情弹出框
        avtive(id) {
            // console.log(id, 'active,,id');
            // this.$router.push({ params: { id: id }});
        },
        // 未处理函数--不要了（产品说的）
        unhandle(row) {
            // this.dialogFormVisible = true
            // this.bus.$emit('disappear', true)
            // this.riskId = row.id
            console.log('不要了（产品说的）')
        },
        async getRiskEventReason() {
            const param = {
                eventType: this.tabPosition === '全部' ? '' : Number(this.tabPosition)
            }
            const res = await getRiskEventReason(param)
            this.resonList = res.data
        },
        // 确认
        async confirm(row) {
            if (this.isSafe) {
                this.safe = 2 // 完成
            } else {
                this.safe = 3 // 无需帮助
            }
            console.log('row.id:', this.riskId)
            const data = {
                processDetails: this.textarea,
                processStatus: this.safe,
                riskId: this.riskId,
                superiorHelp: 0,
                eventReason: this.resonId
            }
            const res = await updateRiskEventStates(data)

            this.dialogFormVisible = false
            this.bus.$emit('disappear', false)
            this.selectRiskEventList()
        },
        // 取消
        cancel() {
            this.dialogFormVisible = false
            this.bus.$emit('disappear', false)
        },
        // 手风琴效果
        toogleExpand(row) {
            const $table = this.$refs.table
            this.tableData.map(item => {
                if (row.id !== item.id) {
                    $table.toggleRowExpansion(item, false)
                }
            })
            $table.toggleRowExpansion(row)
        },
        async selectRiskEventList() {
            try {
                this.loading = true
                const data = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    condition: this.keyWord,
                    bed: 1,
                    riskEventType: this.tabPosition === '全部' ? '' : Number(this.tabPosition),
                    orgId: this.streetArr.length ? this.streetArr[this.streetArr.length - 1] : '',
                    sysId: this.sysId,
                    orderBy: this.orderBy,
                    eventReasonType: this.resonId
                }
                const res = await selectRiskEventList(data)
                this.loading = false
                this.tableData = res.data.rows
                this.total = res.data.total
            } catch {
                this.loading = false
            }
        },
        status(val) {
            let e = ''
            switch (val) {
                case 0:
                    e = '防盗风险'
                    break
                case 1:
                    e = '火灾风险'
                    break
                case 2:
                    e = '室外突发'
                    break
                case 3:
                    e = '室内突发'
                    break
                case 4:
                    e = '走丢事件'
                    break
                case 5:
                    e = '紧急事件'
                    break
                case 6:
                    e = '燃气泄漏事件'
                    break
                case 9:
                    e = '健康风险'
                    break
            }
            return e
        },
        // 翻页
        handleCurrentChange(val) {
            this.loading = true
            this.pageNum = val
            this.selectRiskEventList()
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.selectRiskEventList()
        },
        tableRowClassName({ row, rowIndex }) {
            if (rowIndex % 2 === 0) {
                return 'statistics-warning-row'
            } else {
                return ''
            }
        },
        handleClick(value) {
            if (value === '2' || value === '4') {
                this.$message.info('该功能暂未上线，敬请期待！')
                return false
            }
            this.tabPosition = value
            this.resonId = ''
            this.keyWord = ''
            this.orgId = ''
            this.streetArr = []
            this.sysId = ''
            this.pageNum = 1
            if (this.tabPosition !== '全部') {
                this.show_tabs = false
            } else {
                this.show_tabs = true
            }
            this.loading = true
            this.selectRiskEventList()
            this.getRiskEventReason()
        },
        async downLoad() {
            const res = await downloadRiskEvent({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                condition: this.keyWord,
                bed: 1,
                riskEventType: this.tabPosition === '全部' ? '' : Number(this.tabPosition),
                orgId: this.orgId,
                sysId: this.sysId,
                orderBy: this.orderBy,
                eventReasonType: this.resonId
            })
            downloadStream(res, '智慧居家安全表.xls')
        },
        getCommunity(val) {
            this.orgId = val
        },
        sortChangeInit(column, prop, order) {
            if (column.prop === 'alertTime') {
                if (column.order === 'ascending') {
                    this.orderBy = 1
                } else if (column.order === 'descending') {
                    this.orderBy = 0
                } else {
                    this.orderBy = null
                }
            } else if (column.prop === 'responseTime') {
                if (column.order === 'ascending') {
                    this.orderBy = 3
                } else if (column.order === 'descending') {
                    this.orderBy = 2
                } else {
                    this.orderBy = null
                }
            }
            this.pageNum = 1
            this.loading = true
            this.selectRiskEventList()
        },
        // 获取机构列表
        getOrgan() {
            serviceNameList().then(res => {
                this.organList = res.data
            })
        },
        // 获取组织列表
        getStreetList() {
            streetList().then(res => {
                this.streetList = res.data
            })
        },
        // 查看风险日志
        async seeLog(row) {
            this.riskId = row.id
            this.showLog = true
        }
    }
}
</script>

<style lang="scss" scoped>
.riskBox {
    // margin: 20px;
    background: #fff;
    min-height: calc(100vh - 130px);
    .tabs {
        padding: 20px 5px;
        display: flex;
        justify-content: space-between;
        .menuTabs {
            display: flex;
            .tabItem {
                padding: 10px 20px;
                background: #f5f5f5;
                font-size: 14px;
                color: #606266;
                cursor: pointer;
            }
            .tabItem:hover {
                color: #3fcf89;
            }
            .active-tab {
                background: #3fcf89;
                border-radius: 4px;
                color: #fff;
            }
            .active-tab:hover {
                color: #fff;
            }
        }
    }
    .tabs_right {
        text-align: left;
        padding-left: 15px;
        .el-select {
            margin-right: 20px;
        }
        .search {
            margin: 0 20px;
        }
    }
}
.content {
    padding: 15px;
    .demo-table-expand {
        width: 100%;
    }
    .map {
        width: 100%;
        height: 400px;
        background-color: #fff;
        position: relative;
        overflow: hidden;
        /* background-color: green; */
        border: 1px solid black;
    }
}

.date-box {
    display: flex;
    height: 40px;
    align-items: center;
    .date-txt {
    }
    .num-box {
        color: orange;
        margin-top: -10px;
        margin-left: 5px;
        font-family: DIN;
        i {
            font-size: 12px;
        }
        span {
            font-size: 18px;
            font-weight: bold;
            font-style: italic;
        }
    }
}
.processStatus {
    display: flex;
    align-items: center;
    img {
        margin-right: 5px;
    }
}
/deep/ .el-radio-button__inner:hover {
    color: #3fcf89;
}
/deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #fff;
}
.lable-box {
    display: inline-block;
    .labelFont {
        font-size: 14px;
        display: inline-block;
        padding-right: 12px;
        color: #606266;
    }
}
.chu_img {
    width: 20px;
}
</style>
